import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useNavigate, Navigate } from 'react-router-dom';
import { MenuItem, Select, FormControl, InputLabel, Tooltip, IconButton, Card, Button } from "@mui/material";
import './globals.css';
import Co2DataCard from './components/co2data';
import GuidelineInfo from './guidelineInfo';
import LoadingJS from './components/Loading';
import config from './components/config';
import Login from './pages/Login';
import { selectUser, logout } from './store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import Dashboard from './pages/Dashboard';

async function fetchPythonBackendData(indoorImei, outdoorImei, guideline, api_key) {
  try {
    const res = await fetch(`${config.backendUrl}/data?imei=${indoorImei}&outdoor_imei=${outdoorImei}&guideline=${guideline}&api_key=${api_key}`);
    if (!res.ok) {
      throw new Error(`Failed to fetch data: ${res.statusText}`);
    }
    return res.json();
  } catch (err) {
    console.error("Error fetching data from Python backend:", err);
    throw err;
  }
}

function App() {

  const user = useSelector(selectUser);
  const dispatch = useDispatch();

  const AuthRequired = (props) => {
    const user = useSelector(selectUser)
    const navigate = useNavigate();
    if (user) {
      return props.children
    }
    else {
      return <Navigate to='/login' replace></Navigate>
    }

  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={!user ? <Login></Login> : <Navigate to='/' replace></Navigate>} />
        <Route path="/dashboard" element={<AuthRequired><Dashboard></Dashboard></AuthRequired>} />
        <Route path="/" element={<Navigate to='/dashboard'></Navigate>} />
      </Routes>
    </Router>
  );
}

export default App;
