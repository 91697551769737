import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../store/userSlice";
import { login as performLogin } from "../services/auth"

const Login = () => {
    //const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const dispatch = useDispatch();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const auth_res = await performLogin(email, password)
        if (auth_res.status_code == 401 || auth_res.status_code == 403) {
            setError(auth_res.message)
        }
        if (auth_res.status_code == 201) {
            setError(null)
            localStorage.setItem('user', JSON.stringify(auth_res))
            dispatch(
                login({
                    username: auth_res.username,
                    email: auth_res.email,
                    key: auth_res.key,
                    logo: auth_res.logo,
                    loggedIn: true,
                })
            );
        }

    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100 text-blue-900">
            <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-md rounded-lg">
                <h1 className="text-2xl font-bold text-center text-gray-700">Login</h1>
                {error && (
                    <div className="p-4 mb-4 text-red-700 bg-red-100 border border-red-400 rounded">
                        {error}
                    </div>
                )}
                <form className="space-y-4" onSubmit={handleSubmit}>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Email</label>
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full px-3 py-2 mt-1 border rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Password</label>
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-3 py-2 mt-1 border rounded-md focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                    </div>
                    <button
                        type="submit"
                        className="w-full py-2 text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Login;
