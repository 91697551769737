import axios from 'axios';

export const login = async (username, password) => {
    const res = await axios.post("https://atmos.urbansciences.in/adpl/login", {
        username: username,
        password: password
    }, { headers: { "Content-Type": "multipart/form-data" } })

    return res.data;


}